











import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class AssessNav extends Vue {
    @Prop({ type: Array, required: true, default: () => [] })
    assessNavList: any;
}





















































import { Vue, Component, Watch } from "vue-property-decorator";
import { Tabs, TabPane } from "element-ui";

@Component({
    components: {
        "el-tabs": Tabs,
        "el-tab-pane": TabPane,
    },
})
export default class CaseList extends Vue {
    projectListLoading: boolean = true; //控制项目列表是否显示loading

    caseTypeList: {} = {}; //案例类型列表
    caseListTitle: {} = {
        zh: "2021全球百佳技术转移案例",
        en: "100 Global Best Practices for Technology Transfer 2021",
    };

    /**获取和设置案例类型列表 */
    getCaseType = () => {
        const getOpt = this.$api.apiList.getTypeListt;
        const opt = getOpt();

        /**设置案例类型列表 */
        const setCaseList = (data: any) => {
            // this.$set(this.caseTypeList, 0, { en: "All", zh_CN: "所有案例" });
            for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    // console.log(data.[key]);

                    const element = data[key];
                    this.$set(this.caseTypeList, key, element);
                }
            }
        };

        this.$axios({
            method: opt.method,
            url: opt.url,
        })
            .then((res: any) => {
                setCaseList(res.data.data);
            })
            .catch(() => {});
    };

    /**项目列表 */
    projectList: any[] = [];

    activeName = this.$route.params.type_id ? this.$route.params.type_id : "1"; //项目列表导航的active(已评、未评)

    /**项目名,返回系统对应的语言,如没有则返回原文 */
    project_name(el: any) {
        let lang =
            this.$i18n.locale == "en"
                ? "en"
                : this.$i18n.locale == "zh"
                ? "cn"
                : "cn";

        return el[`name_${lang}`] ? el[`name_${lang}`] : el.name;
    }

    /**tab点击事件 */
    handleClick(e: any) {
        this.projectList = [];
        this.projectListLoading = true;
        this.getListData(this.activeName);
    }

    /**点击项目 */
    handelProject(el: any, item: any, index: any) {
        this.$router.push({
            name: "case-detail",
            params: {
                case_id: item.id,
            },
        });
    }

    /**获取项目列表 */
    getListData(type_id: any) {
        const getOpt = this.$api.apiList.getCaseList;
        const opt = getOpt(type_id);

        this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers,
        })
            .then((res: any) => {
                this.setListData(res.data.data);
                this.projectListLoading = false;
            })
            .catch(() => {
                this.projectListLoading = false;
            });
    }

    /**整理项目列表 */
    setListData(list: any) {
        this.projectList = list;
    }

    mounted() {
        this.getCaseType();
        this.getListData(this.activeName);
    }
}

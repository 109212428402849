





































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
// import { PageHeader } from "element-ui";
import { PageHeader } from "@/components/al/pageHeader";
@Component({
  components: {
    "el-page-header": PageHeader,
  },
})
export default class ProjectInfo extends Vue {
  @Prop({ type: Object, required: true, default: () => {} })
  project: any;

  @Prop({
    type: Array,
    required: false,
    default: () => [
      "name",
      "company",
      "type_name",
      "basic_info",
      "introduction",
      "advantage",
      "zhicheng",
      "tuijian",
    ],
  })
  project_show: any;

  actived: number = 0; //0是原文,1是英文,2是中文

  /**这些key是会显示出来的 */
  // project_show: any = [
  //     // "id",
  //     "name",
  //     // "contact",
  //     // "position",
  //     // "phone",
  //     // "email",
  //     "type_name",
  //     "basic_info",
  //     "introduction",
  //     "advantage"
  // ];

  /**项目 */
  project_modified: any = {};

  goBack() {
    this.$router.back();
  }

  /**设置项目语言版本导航actived */
  setNavActived(index: number) {
    this.actived = index;
  }

  /**项目语言版本导航点击事件 */
  handleNavItem(index: number | string) {
    this.setNavActived(Number(index));
  }

  /**设置项目数据格式 */
  setProjectData(project: any) {
    console.log(project);

    let timer = (object: any, key: any, data: any, i: any) => {
      setTimeout(() => {
        this.$set(object, key, data);
      }, 100 * i);
    };

    let i: number = 0;
    this.project_modified = {};
    let that = this;
    for (const key in project) {
      if (project.hasOwnProperty(key)) {
        i++;
        const element = project[key];
        const isShow = this.project_show.findIndex((val: any) => val == key);
        if (isShow !== -1) {
          timer(this.project_modified, key, element, i);
        }
      }
    }
  }

  /**设置项目item title */
  translateIndex(index: any) {
    let indexContent: string;

    switch (index) {
      case "id":
        indexContent = `案例 ID`;
        break;
      case "name":
        indexContent = `案例名称`;
        break;
      case "company":
        indexContent = `案例机构`;
        break;
      case "contact":
        indexContent = `联系人`;
        break;
      case "position":
        indexContent = `职位`;
        break;
      case "phone":
        indexContent = `联系电话`;
        break;
      case "email":
        indexContent = `邮箱`;
        break;

      case "type_name":
        indexContent = `案例类型`;
        break;

      case "basic_info":
        indexContent = `案例概述`;
        break;
      case "introduction":
        indexContent = `案例单位简介`;
        break;
      case "advantage":
        indexContent = `案例呈现典型性、示范性、代表性与重要意义`;
        break;
      case "zhicheng":
        indexContent = `支撑材料`;
        break;
      case "tuijian":
        indexContent = `推荐单位`;
        break;

      default:
        indexContent = index;
        break;
    }

    return indexContent;
  }

  /**设置项目item content */
  translateContent(index: any, item: any) {
    let content: any;
    switch (index) {
      case "technology_ip_name":
      case "cooperation_type_name":
        let _html: string = "";
        for (let i = 0; i < item.length; i++) {
          _html = `${_html}<div style='padding:2px 0;'>${item[i]}</div>`;
        }
        content = `<div>${_html}</div>`;
        break;
      case "ip_number":
        if (item == 0) content = this.$t("scaffold.noData");
        else content = item;
        break;
      default:
        content = item;
        break;
    }

    return content && content != null && content != ""
      ? content
      : this.$t("scaffold.noData");
  }

  @Watch("project")
  projectChange(nv: any, ov: any) {
    const index = this.actived;
    const project = nv;
    this.setProjectData(project);
  }

  @Watch("actived")
  activedChange(nv: any, ov: any) {
    if (JSON.stringify(this.project) != "{}") {
      const project = this.project;
      this.setProjectData(project);
    }
  }

  mounted() {
    /**根据系统语言设定项目显示的语言版本 */
    this.setNavActived(
      this.$i18n.locale == "en" ? 1 : this.$i18n.locale == "zh" ? 2 : 0
    );
    // console.log(this.project);
  }
}

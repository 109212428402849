














import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class PageHeader extends Vue {
    @Prop({ type: [String, Boolean], required: false, default: () => true })
    backbtn: any;

    @Prop({ type: String, required: false, default: () => "返回" })
    backContent: any;

    @Prop({ type: String, required: true, default: () => {} })
    content: any;

    back() {
        this.$emit("back");
    }
}















import { Vue, Component } from "vue-property-decorator";
import ProjectInfo from "@/components/assess/ProjectInfo.vue";
@Component({
    components: {
        ProjectInfo,
    },
})
export default class CaseDetail extends Vue {
    case_id: string = this.$route.params.case_id;
    case_detail: {} = {};

    project_show: any[] = [
        "name",
        // "type_name",
        "basic_info",
        "introduction",
        "advantage",
    ];

    getData(case_id: string) {
        const getOpt = this.$api.apiList.getCaseDetail;
        const opt = getOpt(case_id);

        this.$axios({
            method: opt.method,
            url: opt.url,
        }).then((res: any) => {
            this.case_detail = res.data.data;
        });
    }

    mounted() {
        this.getData(this.case_id);
    }
}
